export const CHOOSE_LESSON = 'CHOOSE_LESSON';
export const TOGGLE_SELECTE_SENTENCE = 'TOGGLE_SELECTE_SENTENCE';

export const SENTENCE_NO = 'SENTENCE_NO';

export const TYPE_PAGE = 'TYPE_PAGE';

export const IS_SENTENCE_SELECTED = 'IS_SENTENCE_SELECTED';

export const IS_SELECTED_TAB_ACTIVE = 'IS_SELECTED_TAB_ACTIVE';

export const CONTENTS_LENGTH = 'CONTENTS_LENGTH';
